<template>
  <div class="refundList page-gray">
    <van-tabs
        v-model="active"
        sticky
        swipeable
        :line-width="15"
        :line-height="4">
      <van-tab title="审核中" name="0"></van-tab>
      <van-tab title="已退款" name="1"></van-tab>
    </van-tabs>
    <div class="list">
      <div v-if="active === '0'">
        <div class="item"  v-for="(item,index) in orderReturnVoUnauditedList" :key="index">
          <div class="head van-hairline--bottom">
            退款编号：{{ item.orderSn }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="null != item.afterSalesId">(售后申请)</span>
          </div>
          <div class="goodsItem" v-for="(product,step) in item.orderReturnItemVos " :key="step">
            <div class="name">
              <div>{{product.name}}</div>
              <div class="state">退款审核中</div>
            </div>
            <div class="goodsInfo">
              <img
                  :src="product.productImg"
                  alt=""
              />
              <div class="right">
                <div class="price">
                  <span>¥</span>
                  <span>{{product.price}}</span>
                </div>
                <div class="sku">数量：{{ product.quantity }}</div>
              </div>
            </div>
          </div>
          <div class="goodsItem" v-if="item.postageProduct">
            <div class="name">
              <div>邮费商品</div>
              <div class="state">退款审核中</div>
            </div>
          </div>
          <div class="count">退款总金额：&nbsp;<span>￥{{ (item.totalPrice+item.postFee).toFixed(2) }}</span>&nbsp;（含邮费{{ item.postFee }}元）&nbsp;&nbsp;&nbsp;
          </div>

          <div class="button van-hairline--top">
            <van-button plain round type="primary" color="#D61619" size="mini" @click="revokeApplication(item)">撤销申请</van-button>
          </div>
        </div>
      </div>
      <div v-if="active == 1">
        <div class="item" v-for="(item,index) in orderReturnReviewedList" :key="index">
          <div class="head van-hairline--bottom">
            订单编号：{{ item.orderSn }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="null != item.afterSalesId">(售后申请)</span>
          </div>
          <div class="goodsItem" v-for="(product,step) in item.orderReturnItemVos " :key="step">
            <div class="name">
              <div>{{product.name}}</div>
              <div class="state" style="color: #E7AC58">{{ orderStatusList[item.orderStatus] }}</div>
            </div>
            <div class="goodsInfo">
              <img
                  :src="product.productImg"
                  alt=""
              />
              <div class="right">
                <div class="price">
                  <span>¥</span>
                  <span>{{ product.price }}</span>
                </div>
                <div class="sku">数量：{{ product.quantity }}</div>
              </div>
            </div>
          </div>
          <div class="goodsItem" v-if="item.postageProduct">
            <div class="name">
              <div>邮费商品</div>
              <div class="state" style="color: #E7AC58">{{ orderStatusList[item.orderStatus] }}</div>
            </div>
          </div>
          <div class="count">退款合计：&nbsp;<span>￥{{ item.totalPrice+item.postFee }}</span>&nbsp;（含邮费{{ item.postFee }}元）&nbsp;&nbsp;&nbsp;
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "refundList",
  data() {
    return {
      active: '0',
      orderReturnVoUnauditedList:[],
      orderReturnReviewedList:[],
      orderStatusList:["未确认","已确认","已完成","已取消","已驳回"],
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.$http.get(`/mall/h5_mall_order/queryUserRefundRecord`).then(res => {
       this.orderReturnVoUnauditedList = res.data.orderReturnVoUnauditedList
       this.orderReturnReviewedList = res.data.orderReturnVoReviewedList
      });
    },
    //撤销申请
    revokeApplication(item){
      this.$dialog.confirm({
        title: '标题',
        message: '确认撤销申请？',
      })
      .then(() => {
        this.$http.post(this.baseMallUrl + `/h5_mall_order/revokeApplication/${item.id}`, {emulateJSON: true}).then(() => {
          this.$toast.success('撤销成功');
          this.init();
        })
      })
      .catch(() => {
        // on cancel
      });

    }
  }
}

</script>
<style lang="less" scoped>
.refundList {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .list {
    flex: 1;
    overflow-y: auto;
  }
}


.item {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 10px 18px;

  .head {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 30px;

    padding: 0 13px;

    &::after {
      border-color: #eee;
    }
  }


  .count {
    line-height: 12px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #212121;

    padding: 0 13px;
    padding-top: 21px - 13px;
    padding-bottom: 13px;

    span {
      color: #D61619;
    }
  }

  .button {
    text-align: right;
    padding: 10px 13px;

    &::after {
      border-color: #eee;
    }

    .van-button {
      padding: 0 10px;
    }
  }
}

.goodsItem {

  padding: 12px 13px;
  background: #fff;

  .name {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div:first-child {
      font-size: 12px;
      color: #333333;
      line-height: 15px;
      width: 179px;
    }

    .state {
      line-height: 15px;
      font-size: 12px;
      color: #de4547;

      &.black {
        color: #333333;
      }
    }
  }
}

.goodsInfo {
  display: flex;
  align-items: center;
  margin-top: 9px;

  &.multiple {
    img {
      margin-right: 5px;
    }
  }

  img {
    width: 95px;
    height: 80px;
    border-radius: 5px;
    min-width: 95px;
    margin-right: 20px;
  }

  .right {
    .price {
      span {
        line-height: 1;
        font-weight: bold;
        color: #d53a26;

        &:last-child {
          font-size: 15px;
          margin-left: 3px;
        }

        &:first-child {
          font-size: 12px;
        }
      }
    }

    .sku {
      background: #eeeeee;
      border-radius: 2px;

      padding: 5px 10px;
      line-height: 10px;
      font-size: 10px;
      color: #666666;

      display: inline-block;
    }

    .tagList {
      margin: 7px 0;
      font-size: 0;

      .tag {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #ff0000;
        border-radius: 3px;
        line-height: 10px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ff0000;

        padding: 2px 5px;
      }
    }

    .time {
      line-height: 10px;
      font-size: 10px;
      color: #e7ac58;
    }
  }
}

</style>
